<template>
  <div class="header-sub">
    <div class="global-container header-sub-menu col-lg-12">
      <div class="row">
        <div class="col-lg-3">
          <h2 class="title-menu">LE BLOG CARIZY</h2>
          <ul>
            <li class="sub-nav-item">
              <a :href="validateUrl('/blog')">
                <span class="menu-label"> Le blog </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-7 relative">
          <div class="col-lg-5">
            <h2 class="title-menu">ARTICLES À LA UNE</h2>
            <div
              v-for="(article, index) in topArticles"
              :key="article.title"
              class="top-articles"
            >
              <ul v-if="index <= 2">
                <li
                  :id="`tooltip-${index}`"
                  class="sub-nav-item"
                  @mouseover="displayPicture(index)"
                >
                  <a :href="article.link" class="article-container">
                    <span class="menu-label">
                      {{ article.title }}
                    </span>
                  </a>
                </li>
                <div
                  :id="`tooltip-target-${index}`"
                  class="article-preview d-none"
                >
                  <v-lazy-image :src="article.image" :alt="article.title" />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";
import UtilMixin from "../../../mixins/UtilMixin";

export default {
  name: "SubMenuAdvice",
  components: {
    VLazyImage,
  },
  mixins: [UtilMixin],
  props: {
    topArticles: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      route: "",
      partner: null,
      displayDiv: false,
    };
  },
  mounted() {
    // Partnaire loading
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("partenaire")) {
      this.partner = urlParams.get("partenaire").toLowerCase();
    }

    this.route = this.getPartenaire(this.partner).route;
  },
  methods: {
    validateUrl(url) {
      return url + this.route;
    },
    displayPicture(id) {
      const targetDiv = document.getElementById("tooltip-target-" + id);
      const otherDiv = document.querySelectorAll(
        ".article-preview:not(#tooltip-target-" + id + ")",
      );

      for (let i = 0; i < otherDiv.length; i++) {
        otherDiv[i].classList.add("d-none");
      }

      targetDiv.className += "d-block";
      targetDiv.classList.remove("d-none");
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/modules/_subMenu";
</style>
